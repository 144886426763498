.container {
  flex: 1;
  margin: 0 20px 20px 0;
  overflow-y: scroll;
  position: relative;
}

.heading {
  position: sticky !important;
  top: 0;
  z-index: 1;
}
