.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.fill {
  display: flex;
  height: calc(100% - 70px); /* account for search bar */
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-height: calc(100vh - 32px); /* account for hydra toolbar */
  overflow: auto;
}

.globalActionsContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 20px;
}
