.toolbarSettingsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.toolbarSettings {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.leftAlign {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
  flex: 1 1 auto;
  width: 33.3%;
}

.rightAlign {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;
  flex: 1 1 auto;
  width: 33.3%;
}

.centerAlign {
  position: fixed;
  margin: 0px 25%;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}

.dispFlex {
  display: flex !important;
}

.capitalize {
  text-transform: capitalize;
}

.releaseNotesOverlay {
  left: calc(50vw - 350px);
  margin: 10vh 0;
  top: 0;
  width: 700px;
}

.reportOverlay {
  display: flex !important;
  align-items: center;
  justify-content: center;
  position: fixed !important;
}

.windowCaptureContainer {
  display: flex;
  flex-direction: column;
  padding: 10px;
}

.windowCapturePreview {
  width: 25vw;
  height: 25vh;
}

.reportTextArea {
  margin: 25px 0;
}
