.container {
  padding: 0 20px;
}

.header {
  display: flex;
  align-items: center;
}

.heading {
  margin-right: 20px !important;
}
