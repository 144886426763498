.container {
  padding: 0 20px 20px 20px;
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
}

.heading {
  margin-right: 20px !important;
}

.propertiesHeading {
  margin-top: 20px !important;
}

.table {
  width: 100%;
}

.cellField {
  width: 20%;
}
