.container {
  display: flex;
  padding: 0 20px;
}

.title {
  width: 400px;
}

.form {
  margin: 20px 0 20px 10px;
  width: 400px;
}

.propertiesContainer {
  margin-top: 30px;
}

.instructions {
  margin-bottom: 15px;
  white-space: pre-wrap;
}
