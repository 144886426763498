.container {
  padding: 0 20px;
}

.form {
  width: 300px;
}

.form {
  margin-top: 30px;
}
