.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.header {
  display: flex;
  align-items: center;
}

.heading {
  margin-right: 20px !important;
}

.container,
.table {
  width: 100%;
}

.tableWrapper {
  height: 100%;
  overflow-y: scroll;
}

.cellIdentifier {
  width: 10%;
}

.cellName {
  width: 30%;
}
