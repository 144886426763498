.container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
  width: 100%;
  min-height: 300px;
}

.resultsContainer {
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  border: 1px solid white;
  padding: 10px;
}

.spinner {
  width: 100%;
}

.searchResultsList {
  margin: 0;
  padding: 0 0 0 15px;
  list-style: disclosure-closed;
}

.searchResultItem {
  margin-bottom: 10px;
}
