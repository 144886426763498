.container {
  display: flex;
  flex-direction: column;
}

.listContainer {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 35px 20px 20px 0;
  padding: 10px;
  border: 1px solid white;
  width: 200px;
  overflow-y: scroll;
}

.propertyContainer {
  margin-bottom: 10px;
}

.valueSelect {
  width: 100%;
}

.clearFiltersButton{
  margin-bottom: 10px;
}
