.container {
  display: flex;
  padding: 0 20px 20px 20px;
}

.form {
  margin: 20px 0 0 10px;
  width: 400px;
}

.propertiesContainer {
  margin-top: 30px;
}

.previewContainer {
  /* display: flex;
  align-items: center;
  margin-bottom: 15px; */
}

.label {
  /* font-size: 12px; */
}

.dash {
  margin: 6px 8px 0px 8px;
}

.channelNameFormGroup {
  margin-bottom: 0 !important;
}
