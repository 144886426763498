.container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 20px 20px;
  width: 100%;
}

.categoryGroup {
  width: 100%;
}

.categoryPath {
  margin: 5px;
}