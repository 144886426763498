.container {
  width: 100%;
  overflow-y: scroll;
  padding-bottom: 15px;
}

.categoryHeader {
  font-size: 30px !important;
}

.subcategoryHeader {
  margin-bottom: 0;
}

.categoryRuler {
  margin-top: 0;
  color: white;
}

.parentCategoryList {
  list-style: none;
  padding: 0 20px 0 5px;
  margin: 0;
}

.subcategoryList {
  column-count: 3;
  list-style: none;
  padding: 0 0 0 5px;
  margin: 0;
}

.categoryLinkLight {
  color: black !important;
}

.categoryLinkDark {
  color: white !important;
}

.categoryLinkDark:hover {
  text-decoration: none;
  color: lightgrey !important;
}

.categoryLinkLight:hover {
  text-decoration: none;
  color: grey !important;
}
