.container,
.table {
  width: 100%;
}

.container {
  overflow: auto;
  padding: 0 20px 20px 0;
}

.icon {
  margin-left: 5px;
}

.categoryFields {
  width: 400px;
}

.propertyDropdownContainer {
  flex-direction: row !important;
  align-items: center;
  gap: 15px;
  margin: 20px 10px 10px !important;
}

.propertyEditTableContainer {
  margin: 10px 0;
}

.propertyEditTable {
  width: 100%;
}

.defaultPropertyList {
  margin: 0;
  padding-left: 15px;
  max-width: 300px;
  list-style: disclosure-closed;
}

.defaultValues {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.propertyEditTableFormGroup {
  margin: 0 !important;
}

.name {
  width: 15%;
  vertical-align: middle !important;
}

.description {
  width: 25%;
}

.min,
.max {
  width: 5%;
}

.ordinality {
  width: 3%;
}

.sizeGrade {
  display: flex;
}

.sizeGradeDialog {
  max-height: 500px;
}

.sizeGradeDialogBody {
  overflow-y: auto;
}

.sizeGradeCopySelectRow {
  display: flex;
  justify-content: space-between;
  margin: 5px 15px 15px;
}

.sizeGradeLabelRow {
  display: flex;
  margin-bottom: 15px;
}

.sizeGradeLabel {
  width: 50%;
  text-align: center;
  font-size: 1rem;
}

.sizeGradeRow {
  display: flex;
  justify-content: space-between;
}

