.container {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.content {
  display: flex;
  overflow-y: scroll;
}

.header {
  display: flex;
  align-items: center;
}

.tableWrapper {
  overflow-y: scroll;
}
