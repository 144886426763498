.searchContainer {
  display: flex;
  flex: 1;
}

.inputField {
  margin-left: 15px;
}

.inputField>input {
  left: 5px;
}

.itemListContainer {
  padding: 10px;
  width: 700px;
}

.itemListContainer.empty {
  padding-top: 5px;
}

.itemList {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.activeItemTag {
  outline: rgba(45, 114, 210, 0.6) auto 2px;
  background-color: #ABB3BF !important;
  /* Default tag color */
}

.searchResults {
  padding-top: 10px;
}

.searchResults.empty {
  padding-top: 0;
}

.empty {
  padding-bottom: 10px;
}

.searchResultsList {
  margin: 10px 0 0 0;
  padding-left: 20px;
  list-style: disclosure-closed;
}

.searchGroups {
  margin-top: 10px;
}

.categoryPath {
  margin: 0;
}

.searchResultItem {
  margin-bottom: 10px;
}
