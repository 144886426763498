.sortable:hover {
  cursor: pointer;
}

.icon {
  margin-left: 1px;
}

.stickyTable {
  position: relative;
}

.stickyHeader {
  position: sticky;
  top: 40px;
  z-index: 1;
  border-bottom: 1px solid white;
}

td:first-child {
  white-space: nowrap;
}
